import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ContentLoopManager from "./ContentLoopManager";
import ContentFilterService from "./ContentFilterService";
import { SpotContext } from "./Context";
import SpotTimer from "./SpotTimer";
import fetchConfiguration from "./ConfigurationService";
import Spot from "./Spot";

import { notify, EventType } from "EventObserver/EventPublisher";

type Props = {
  children: any;
};

type Params = {
  displayId: string;
};

// Using params.get(`?${key}) for Chrome 49
// Using params.get(key) for modern browsers

function getUrlParam(key: string) {
  const params = new URLSearchParams(window.location.search);
  return params.get(key) || params.get(`?${key}`) || null;
}

function setupContentLoopManager(displayId: string) {
  const requestedDisplayRole = getUrlParam("displayRole");
  const permittedDisplayRole = ContentFilterService.isValidDisplayRole(requestedDisplayRole)
    ? requestedDisplayRole
    : null;
  return new ContentLoopManager(displayId, permittedDisplayRole);
}

const ContentProvider = ({ children }: Props) => {
  const { displayId } = useParams<Params>() as Params;
  if (!displayId) throw new Error(`Invalid displayId: ${displayId}`);

  const contentLoopManagerRef = useRef<ContentLoopManager>(setupContentLoopManager(displayId));

  const [spot, setSpot] = useState<Spot>();
  const [startTime, setStartTime] = useState<number>(Date.now());
  const [environment, setEnvironment] = useState<string>(""); // FIXME: environment is production/preview not process.env - rename? Boolean?
  const [language, setLanguage] = useState<string>("");
  const [enableLayout, setEnableLayout] = useState<boolean>(false);
  const [timezone, setTimezone] = useState<string | undefined>(undefined);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const config = await fetchConfiguration(displayId);
        if (config) {
          setEnvironment(config.environment);
          setLanguage(config.language);
          setEnableLayout(config.enable_layout);
          setTimezone(config.timezone);
          contentLoopManagerRef.current.setEnvironment(config.environment);
        }
      } catch (error) {
        console.error("An error occurred while fetching the configuration:", error);
      }
    };
    fetchConfig();
  }, [displayId]);

  const cycleContent = async () => {
    console.log("Cycling content...");
    if (spot && spot.contents.length > 0)
      notify(EventType.Play, { content: spot.mainContent(), environment });
    const newSpot = contentLoopManagerRef.current.getContent();
    if (enableLayout) newSpot.renderHtml();
    setSpot(newSpot);

    setStartTime(Date.now());
  };
  const duration = spot ? spot.duration() * 1000 : 0;

  const contextValue = {
    state: { spot, environment, language, enableLayout, timezone },
    actions: {},
  };

  return (
    <SpotContext.Provider value={contextValue}>
      <SpotTimer duration={duration} onTimeout={cycleContent} startTime={startTime} />
      {children}
    </SpotContext.Provider>
  );
};

export { ContentProvider as default };
