import { EventType } from "EventObserver/EventPublisher";
import { type EventSubscriber } from "EventObserver/EventSubscriber";
import { DataPipeline } from "DataPipeline/DataPipeline";
import { DataPipelineLoopEventPayload } from "DataPipeline/DataPipelineEventPayload";
import Bugsnag from "@bugsnag/js";

export class DataPipelineLoopEventSubscriber implements EventSubscriber {
  public receiveEvent(
    eventType: EventType,
    payload: { content: Record<string, never>; environment: string }
  ): void | boolean | Promise<void | boolean> {
    if (eventType !== EventType.Loop) return false;
    const { content = {}, environment } = payload;
    if (environment === undefined) {
      const errorMessage = `[DataPipelineLoopEventSubscriber] Invalid payload: ${JSON.stringify(
        payload
      )}`;
      Bugsnag.notify(new Error(errorMessage));
      return false;
    }
    const loopEventPayload = new DataPipelineLoopEventPayload(content, environment);
    DataPipeline.record(loopEventPayload);
    return true;
  }
}
