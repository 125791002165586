import Content from "Content/Content";
import { DataPipeline } from "DataPipeline/DataPipeline";
import { DataPipelinePlayEventPayload } from "DataPipeline/DataPipelineEventPayload";
import { type EventSubscriber } from "EventObserver/EventSubscriber";
import { EventType } from "EventObserver/EventPublisher";
import Bugsnag from "@bugsnag/browser";

//FIXME: implement a type check for the payload?
export class DataPipelinePlayEventSubscriber implements EventSubscriber {
  public receiveEvent(
    eventType: EventType,
    payload: { content: Content; environment: string }
  ): boolean {
    if (eventType !== EventType.Play) return false;
    const { content, environment } = payload;
    if (content === undefined || environment === undefined) {
      const errorMessage = `[DataPipelinePlayEventSubscriber] Invalid payload: ${JSON.stringify(
        payload
      )}`;
      Bugsnag.notify(new Error(errorMessage));
      return false;
    }
    if (this.contentGoesToPipeline(content)) {
      const playEventPayload = new DataPipelinePlayEventPayload(content, environment);
      DataPipeline.record(playEventPayload);
    }
    return true;
  }

  // `content.type` can be "rss_feed", "big_notice", "alert", ad mime type <"video" or "image">
  // can also be "weather", "layout", "html", but I don't think those will ever make it here?
  private contentGoesToPipeline(content: Content) {
    if (this.isRssFeed(content)) {
      return false;
    }
    if (this.isNotice(content)) {
      return true;
    }
    if (this.isAlert(content)) {
      return true;
    }
    if (this.isAd(content)) {
      return true;
    }
    return false;
  }

  /* currently, we consider an ad as any content with a callback_url */
  private isAd(content: Content): boolean {
    return content && content.callback_url?.length > 0;
  }

  private isRssFeed(content: Content): boolean {
    return content && content.type === "rss_feed";
  }

  private isNotice(content: Content): boolean {
    return content && content.type === "big_notice";
  }

  private isAlert(content: Content): boolean {
    return content && content.type === "alert";
  }
}
