import { EventType, notify } from "EventObserver/EventPublisher";
import ContentListCacheService from "./ContentListCacheService";
import ContentLoop from "./ContentLoop";
import Spot from "./Spot";

export default class ContentLoopManager {
  private displayId: string;
  private permittedDisplayRole: string | null = null;
  private currentLoop: ContentLoop = new ContentLoop([]);
  private nextLoop: ContentLoop | undefined = undefined;
  private isLoadingNextLoop = false;
  private environment: string = "preview";

  constructor(displayId: string, permittedDisplayRole: string | null) {
    this.displayId = displayId;
    this.permittedDisplayRole = permittedDisplayRole;
  }

  public setEnvironment(environment: string) {
    this.environment = environment;
  }

  public getContent(): Spot {
    if (this.shouldLoadNextLoop()) this.loadNextLoop();
    if (this.currentLoop.empty()) this.cycleCurrentLoop();
    return this.currentLoop.shift();
  }

  private shouldLoadNextLoop(): boolean {
    return this.currentLoop.almostEmpty() && this.nextLoop === undefined && !this.isLoadingNextLoop;
  }

  private loadNextLoop() {
    console.log("currentLoop almost empty - loading next");
    this.isLoadingNextLoop = true;
    ContentListCacheService.loadLoop(this.displayId, this.permittedDisplayRole)
      .then((newLoop: ContentLoop) => {
        this.nextLoop = newLoop;
        this.isLoadingNextLoop = false;
      })
      .catch((err) => {
        console.warn(`loading next loop failed: ${err}`);
        this.isLoadingNextLoop = false;
      });
  }

  private cycleCurrentLoop() {
    console.log("currentLoop empty - assuming next");
    notify(EventType.Loop, { content: {}, environment: this.environment });
    if (this.nextLoop) {
      this.currentLoop = this.nextLoop;
      this.nextLoop = undefined;
    } else {
      this.currentLoop.reset();
    }
  }
}
